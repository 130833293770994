
import {useEffect, useState} from 'react';

import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageSelector from "../fragments/form/ImageSelector"
import DropdownSelector from "../fragments/form/DropdownSelector"
import SubmitButton from "../fragments/form/SubmitButton"
import SearchSelector from '../components/SearchSelector/RegionSelect';
import GooglePlacesAutocomplete from "../components/Organizations/GooglePlacesAutocomplete";
import CountryApi from "../services/countries";
import StateApi from "../services/states";
import MunicipalityApi from "../services/municipalities";
import CircularProgress from "@mui/material/CircularProgress";

function CreateOrganizationForm(props) {
    const [formData, setFormData] = useState(new FormData());
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        props.onSubmit(formData, props.location)
    }

  const updateLocationFields = (id, name, value) => {
    let newLocation = {...props.location}
    if (id === 'country') {
      newLocation.country_id = value;
      newLocation.country = name;
    }
    if (id === 'state') {
      newLocation.state_id = value;
      newLocation.state = name;
    }
    if (id === 'city') {
      newLocation.city_id = value;
      newLocation.city = name;
    }
    props.handleSetLocation(newLocation);
  }

  const resetLocationFields = (id) => {
    if (!props.location) {
      return;
    }

    let newLocation = { ...props.location };
    if (id === 'country') {
      newLocation.country = '';
      newLocation.country_id = null;
      newLocation.state = '';
      newLocation.state_id = null;
      newLocation.city = '';
      newLocation.city_id = null;
    } else if (id === 'state') {
      newLocation.state = '';
      newLocation.state_id = null;
      newLocation.city = '';
      newLocation.city_id = null;
    } else if (id === 'city') {
      newLocation.city = '';
      newLocation.city_id = null;
    }
    props.handleSetLocation(newLocation);
  };

  const onChangeLocation = (id, value) => {
    let newLocation = { ...props.location, [id]: value }
    props.handleSetLocation(newLocation);
  }

  const handlePlaceSelected = async(place) => {
    if (!place || !place.address_components || !place.geometry) {
      console.error("Invalid place object:", place);
      return;
  }
    const { address_components: components, geometry , formatted_address} = place;
    let country = "";
    let state = "";
    let city = "";
    let zip = ""; 
    let address_2 = "";

    const newLocation = { ...props.location };
    newLocation.address_1 = formatted_address;

    const lat = geometry?.location?.lat();
    const lng = geometry?.location?.lng();
  
    if (lat && lng) {
      newLocation.latitude = lat;
      newLocation.longitude = lng;
    }

    for(let component of components){
       const { types, long_name, short_name } = component;
       if (types.includes("country")) {
            newLocation.country = long_name;
            country = long_name;
       }

      if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
        newLocation.state = component.long_name;
      }

      if (types.includes("administrative_area_level_2") && !state) {
        state = component.long_name;
        newLocation.state = component.long_name;
      }

      if (types.includes("locality")) {
        city = component.long_name;
        newLocation.city = component.long_name;
      }

      if (types.includes("postal_code")) {
        zip = component.long_name;
        newLocation.zip = component.long_name;
      }

      if (types.includes("subpremise")) {
        address_2 = long_name; 
        newLocation.address_2 = long_name;
      }else{
        newLocation.address_2 = formatted_address
      }

    }
    setLoading(true);
    await getIds(newLocation)
    setLoading(false);
  };

  const getIds = async (locationData) => {
    try {
      let countryId = null;
      let stateId = null;
      let cityId = null;

      const newLocation = { ...locationData };
      let { country, state, city } = locationData

      if (country) {
        const countryResponse = await countryApi.getCountriesByName(country);
        const countryData = countryResponse.find(item =>
          item.country.trim().toLowerCase() === country.trim().toLowerCase()
        );
        if (countryData && countryData.id) {
          countryId = countryData?.id;
          newLocation.country_id = countryId
          newLocation.country = country
        } else {
          countryId = null;
          newLocation.country_id = null;
          props.handleSetLocation(newLocation);
          return;
        }
      }

      if (countryId && state) {
        const stateResponse = await stateApi.getStatesByName(countryId, state);
        const stateData = stateResponse.find(item =>
          item.state.trim().toLowerCase() === state.trim().toLowerCase()
        );
        if (stateData && stateData.id) {
          stateId = stateData?.id;
          newLocation.state_id = stateId
          newLocation.state = state
        } else {
          stateId = null;
          newLocation.state_id = null;
          props.handleSetLocation(newLocation);
          return;
        }
      }

      if (stateId && city) {
        const cityResponse = await municipalityApi.getCitiesByName(stateId, city);
        const cityData = cityResponse.find(item =>
          item.city.trim().toLowerCase() === city.trim().toLowerCase()
        );
        if (cityData && cityData.id) {
          cityId = cityData?.id;
          newLocation.city_id = cityId
          newLocation.city = city
        } else {
          cityId = null;
          newLocation.city_id = null
        }
      }
      props.handleSetLocation(newLocation);
    } catch (error) {
      console.error("Error:", error);
    }
  }

    return (
      <form onSubmit={onSubmit}>
            <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category"  onLoad={() => onChange("category_id", 0)} />
            <TextField id="name" required={true} onChange={onChange} label="Title" onLoad={() => onChange("name", "")}  />
            <TextAreaField id="description" required={true} onChange={onChange} label="Description" rows="10" cols="20" onLoad={() => onChange("description", "")} />
            <TextField id="website_url" required={false} onChange={onChange} label="Website" onLoad={() => onChange("website_url", "")}  />
            <TextField id="twitter_name" required={false} onChange={onChange} label="Twitter Name" onLoad={() => onChange("twitter_name", "")}  />
            <TextField id="facebook_name" required={false} onChange={onChange} label="Facebook Name" onLoad={() => onChange("facebook_name", "")}  />
            <TextField id="instagram_name" required={false} onChange={onChange} label="Instagram Name" onLoad={() => onChange("instagram_name", "")}  />
            <TextField id="linkedin_name" required={false} onChange={onChange} label="LinkedIn Name" onLoad={() => onChange("linkedin_name", "")}  />
            <TextField id="email_address" required={false} onChange={onChange} label="Email Address" onLoad={() => onChange("email_address", "")}  />
            <TextField id="phone_number" required={false} onChange={onChange} label="Phone Number" onLoad={() => onChange("phone_number", "")}  />
            <div className='d-flex align-items-center'>
              <GooglePlacesAutocomplete onPlaceSelected={handlePlaceSelected} onChange={onChangeLocation} label="Address 1 (Detect by google)" required={true} value={props.location ? props.location.address_1 : ''} />
              {loading && (
                <CircularProgress color="inherit" size={'small'} sx={{ width: '18px', height: '18px', marginLeft: '6px'}}/>
              )}
            </div>
            {/* <TextField id="address_1" required={true} onChange={onChangeLocation} label="Address 1" onLoad={() => onChangeLocation("address_1","")}  /> */}
            <TextField id="address_2" required={false} onChange={onChangeLocation} label="Address 2" onLoad={() => onChangeLocation("address_2","")}  value={props.location.address_2 || ""} />
            <TextField id="city" required={true} onChange={onChangeLocation}  label="City" onLoad={() => onChangeLocation("city", "")}  value={props.location.city || ""} />
            <TextField id="state" required={true} onChange={onChangeLocation} label="State" onLoad={() => onChangeLocation("state","")}  value={props.location.state || ""}/>
            <TextField id="country" required={true} onChange={onChangeLocation}  label="Country" onLoad={() => onChangeLocation("country", "")} value={props.location.country || ""} />
            <TextField id="zip" required={true} onChange={onChangeLocation} label="Zip" onLoad={() => onChangeLocation("zip", "")}  value={props.location.zip || ""}/>
            {/*--- */}
            <div className='create-organization-wrapper col-md-10'>
            <h6 >Selected Region for in app Browsing:</h6>
            <SearchSelector
              id="country"
              required={false}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.countries}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="Country"
              prompt="Please search and select a country" />
            <SearchSelector
              id="state"
              required={false}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.states}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="State"
              prompt="Please select a country before select a state"
            />
            <SearchSelector
              id="city"
              required={false}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.cities}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="City"
              prompt="Please select a state before select a city"
            />       
            </div>     
            <TextField id="longitude" type="number" required={true} onChange={onChangeLocation} label="Longtitude" onLoad={() => onChangeLocation("longitude", "")} value={props.location.longitude || null} />
            <TextField id="latitude" type="number" required={true} onChange={onChangeLocation} label="Latitude" onLoad={() => onChangeLocation("latitude","")}  value={props.location.latitude || null}/>
            <ImageSelector id="card_image" required={true} label="Card Image" onChange={onChange} onLoad={() => onChange("card_image", null)}  />
            <ImageSelector id="profile_image" required={true} label="Profile Image" onChange={onChange} onLoad={() => onChange("profile_image", null)}  />
            <CheckboxSelector id="is_published" required={true} onChange={onChange} label="Publish" onLoad={() => onChange("is_published", "0")} />
            <SubmitButton />
        </form>
    );
}

export default CreateOrganizationForm;