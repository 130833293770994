import React, {useState, useEffect} from "react"
import CategoryApi from "../../services/categories";
import OrganizationApi from "../../services/organizations";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities";
import LocationApi from "../../services/locations";
import {useParams} from "react-router-dom";

import Page from "../Page";
import Loading from "../../fragments/Loading";

import EditOrganizationForm from "../../forms/EditOrganizationForm"

let defaultLocation ={
    address_1: "",
    address_2: "", 
    zip: null,
    country_id: null,
    state_id: null,
    city_id: null,
    longitude: null,
    latitude: null,
}

function EditOrganizationPage(props) {
    const [organization, setOrganization] = useState(null);
    const [categories, setCategories] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [location, setLocation] = useState(defaultLocation);

    const organizationApi = new OrganizationApi(props.token);
    const categoryApi = new CategoryApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);
    const locationsApi = new LocationApi(props.token);
    const routeParams = useParams();

    useEffect(() => {
        categoryApi.all().then((categories) => {
            setCategories(Object.values(categories.filter(category => category.is_organization)));
        })
        .catch((err) => {
            console.error(err.message);
        });
        // countryApi.getlist(100).then((response) => {
        //     setCountries([...response]) 
        // })
        // .catch((err) => {
        //     console.error(err.message, err.stack);
        // });

        let updatedLocation = { ...location };
        if (routeParams.countryId) {
            updatedLocation.country_id = routeParams.countryId;
        }
        if (routeParams.stateId) {
            updatedLocation.state_id = routeParams.stateId;
        }
        if (routeParams.municipalityId) {
            updatedLocation.city_id = routeParams.municipalityId;
        }
        if (routeParams.countryId || routeParams.stateId || routeParams.municipalityId) {
            setLocation(updatedLocation);
        }
    },[]);

    useEffect(() => {
        if (routeParams.organizationId) {
            organizationApi.getById(routeParams.organizationId).then((response) => {
                setOrganization(response);
                if(response.location_id){
                    getLocationData(response.location_id)
                }else{
                    setLocation(defaultLocation)
                }
            }).catch((err) => {
                console.error(err.message, err.stack);
            });
        }
    }, [routeParams.organizationId]);

    // const fetchStatesByCountryId = async(countryId)=>{
    //     try {
    //         if(countryId){
    //             let states = await stateApi.getlist(countryId, 100)
    //             setStates([...states])
    //         }else{
    //             setStates([])
    //         }
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // }

    // const fetchCitiesByStateId = async(stateId)=>{
    //     try {
    //         if(stateId){
    //             let cities = await municipalityApi.getlist(stateId, 100)
    //             setCities([...cities])
    //         }else{
    //             setCities([])
    //         }
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // }


    const getLocationData = async (locationId) => {
        try {
            if (!locationId) {
                setLocation(defaultLocation);
                return;
            }
         
            let locationData = await locationsApi.getById(locationId);
           
            if (routeParams.countryId || locationData.country_id) {
                locationData.country_id = routeParams.countryId || locationData.country_id;
            } else if (locationData?.country) {
                const countryResponse = await countryApi.getCountriesByName(locationData.country);
                const country = countryResponse.find(item =>
                    item.country.trim().toLowerCase() === locationData.country.trim().toLowerCase()
                );
                locationData.country_id = country?.id || null;
            } else {
                locationData.country_id = null;
            }

            if (routeParams.stateId || locationData.state_id) {
                locationData.state_id = routeParams.stateId || locationData.state_id;
            } else if (locationData?.state && locationData.country_id) {
                const stateResponse = await stateApi.getStatesByName(locationData.country_id, locationData.state);
                const state = stateResponse.find(item =>
                    item.state.trim().toLowerCase() === locationData.state.trim().toLowerCase()
                );
                locationData.state_id = state?.id || null;
            } else {
                locationData.state_id = null;
            }

            if (routeParams.cityId || locationData.city_id) {
                locationData.city_id = routeParams.cityId || locationData.city_id;
            } else if (locationData?.city && locationData.state_id) {
                const cityResponse = await municipalityApi.getCitiesByName(locationData.state_id, locationData.city);
                const city = cityResponse.find(item =>
                    item.city.trim().toLowerCase() === locationData.city.trim().toLowerCase()
                );
                locationData.city_id = city?.id || null;
            } else {
                locationData.city_id = null;
            }

            setLocation({ ...locationData });
        } catch (err) {
            console.error("Error fetching location data:", err.message);
        }
    };

    // useEffect(() => {
    //     if (location?.country_id) {
    //         fetchStatesByCountryId(location.country_id);
    //     } else {
    //         setStates([])
    //     }
    // }, [location?.country_id]);

    // useEffect(() => {
    //     if (location?.state_id) {
    //         fetchCitiesByStateId(location.state_id);
    //     } else {
    //         setCities([])
    //     }
    // }, [location?.state_id]);


    const handleSetLocation=(newLocation)=>{
        setLocation(newLocation)
    }

    const handleSearchCountry =async(value)=>{
        try {
            if(value){
                let response = await countryApi.getCountryListByLetter(value)
                let arrCountry =  response.countries.map((e)=>({label: e.country , value: e.id,...e}))
                setCountries([...arrCountry])
            }else{
                setCountries([])
            }
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        }
      
    }

    const handleSearchState =async(countryId, value)=>{
        try {
            if(value){
                let response = await stateApi.getStateListByLetter(countryId,value)
                let arrState=  response.states.map((e)=>({label: e.state , value: e.id,...e}))
                setStates([...arrState])
            }else{
                setStates([])
            }
        } catch (error) {
            console.error("Failed to fetch states:", error);
        }
      
    }

    const handleSearchCity = async (stateId, value)=>{
        try {
            if(value){
                let response = await municipalityApi.getCityListByLetter(stateId,value)
                let arrCity=  response.cities.map((e)=>({label: e.city , value: e.id,...e}))
                setCities([...arrCity])
            }else{
                setCities([])
            }
        } catch (error) {
            console.error("Failed to fetch cities:", error);
        }
    }

    const handleSearchLocation = (id, countryId, stateId, value)=>{
        if(id === 'country'){
            handleSearchCountry(value)
        }
        else if(id === 'state'){
            handleSearchState(countryId, value)
        }
        else if(id === 'city'){
            handleSearchCity(stateId, value)
        }else{
            //
        }
    }

    const handleBack=()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId){
            return  `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}`   
        }else{
            return `/organization/${routeParams.organizationId}` 
        }
    }

    if(!organization ) {
        return (<Loading />)
    }
    return(
        <Page
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Edit Organization: ${routeParams.organizationId}`}
        >
            <EditOrganizationForm
                organization={organization}
                categories={categories}
                countries={countries}
                states={states}
                cities={cities}
                location={location}
                handleSetLocation={handleSetLocation}
                handleSearchLocation={handleSearchLocation}
                {...props}
            />
        </Page>
    )
}

export default EditOrganizationPage;