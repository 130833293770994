import {useState, useEffect} from 'react';

import DropdownSelector from "../fragments/form/DropdownSelector"
import ImageSelector from "../fragments/form/ImageSelector"
import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import DateTimeSelector from "../fragments/form/DateTimeSelector"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import SubmitButton from "../fragments/form/SubmitButton"
import SearchSelector from "../components/SearchSelector/RegionSelect"
import CircularProgress from "@mui/material/CircularProgress";
import GooglePlacesAutocomplete from "../components/Organizations/GooglePlacesAutocomplete";
import CountryApi from "../services/countries";
import StateApi from "../services/states";
import MunicipalityApi from "../services/municipalities";

function CreateEventForm(props){
    const [formData, setFormData] = useState(new FormData());
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }
    const updateLocationFields = (id, name, value) => {
        let newLocation = {...props.location}
        if (id === 'country') {
          newLocation.country_id = value;
          newLocation.country = name;
        }
        if (id === 'state') {
          newLocation.state_id = value;
          newLocation.state = name;
        }
        if (id === 'city') {
          newLocation.city_id = value;
          newLocation.city = name;
        }
        props.handleSetLocation(newLocation);
      }
    
      const resetLocationFields = (id) => {
        if (!props.location) {
          return;
        }
    
        let newLocation = { ...props.location };
        if (id === 'country') {
          newLocation.country = '';
          newLocation.country_id = null;
          newLocation.state = '';
          newLocation.state_id = null;
          newLocation.city = '';
          newLocation.city_id = null;
        } else if (id === 'state') {
          newLocation.state = '';
          newLocation.state_id = null;
          newLocation.city = '';
          newLocation.city_id = null;
        } else if (id === 'city') {
          newLocation.city = '';
          newLocation.city_id = null;
        }
        props.handleSetLocation(newLocation);
      };
    
      const onChangeLocation =(id,value)=>{
        let newLocation = {...props.location, [id]: value}
        props.handleSetLocation(newLocation);
    }

    const handlePlaceSelected = async(place) => {
      if (!place || !place.address_components || !place.geometry) {
        console.error("Invalid place object:", place);
        return;
    }
      const { address_components: components, geometry , formatted_address} = place;
      let country = "";
      let state = "";
      let city = "";
      let zip = ""; 
      let address_2 = "";

      const newLocation = { ...props.location };
      newLocation.address_1 = formatted_address;
  
      const lat = geometry?.location?.lat();
      const lng = geometry?.location?.lng();
    
      if (lat && lng) {
        newLocation.latitude = lat;
        newLocation.longitude = lng;
      }
  
      for(let component of components){
         const { types, long_name, short_name } = component;
         if (types.includes("country")) {
              newLocation.country = long_name;
              country = long_name;
         }
  
        if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
          newLocation.state = component.long_name;
        }
  
        if (types.includes("administrative_area_level_2") && !state) {
          state = component.long_name;
          newLocation.state = component.long_name;
        }
  
        if (types.includes("locality")) {
          city = component.long_name;
          newLocation.city = component.long_name;
        }
  
        if (types.includes("postal_code")) {
          zip = component.long_name;
          newLocation.zip = component.long_name;
        }

        if (types.includes("subpremise")) {
          address_2 = long_name; 
          newLocation.address_2 = long_name;
        }else{
          newLocation.address_2 = formatted_address
        }

      }
      setLoading(true);
      await getIds(newLocation)
      setLoading(false);
    };
  
  const getIds = async (locationData) => {
    try {
      let countryId = null;
      let stateId = null;
      let cityId = null;

      const newLocation = { ...locationData };
      let { country, state, city } = locationData

      if (country) {
        const countryResponse = await countryApi.getCountriesByName(country);
        const countryData = countryResponse.find(item =>
          item.country.trim().toLowerCase() === country.trim().toLowerCase()
        );
        if (countryData && countryData.id) {
          countryId = countryData?.id;
          newLocation.country_id = countryId
          newLocation.country = country
        } else {
          countryId = null;
          newLocation.country_id = null;
          props.handleSetLocation(newLocation);
          return;
        }
      }

      if (countryId && state) {
        const stateResponse = await stateApi.getStatesByName(countryId, state);
        const stateData = stateResponse.find(item =>
          item.state.trim().toLowerCase() === state.trim().toLowerCase()
        );
        if (stateData && stateData.id) {
          stateId = stateData?.id;
          newLocation.state_id = stateId
          newLocation.state = state
        } else {
          stateId = null;
          newLocation.state_id = null;
          props.handleSetLocation(newLocation);
          return;
        }
      }

      if (stateId && city) {
        const cityResponse = await municipalityApi.getCitiesByName(stateId, city);
        const cityData = cityResponse.find(item =>
          item.city.trim().toLowerCase() === city.trim().toLowerCase()
        );
        if (cityData && cityData.id) {
          cityId = cityData?.id;
          newLocation.city_id = cityId
          newLocation.city = city
        } else {
          cityId = null;
          newLocation.city_id = null
        }
      }
      props.handleSetLocation(newLocation);
    } catch (error) {
      console.error("Error:", error);
    }
  }

 
    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true)
        props.onSubmit(formData, props.location);
    }
    
    return (
        <form onSubmit={onSubmit}>
            <DropdownSelector id="category_id" required={true}  collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category" />
            <TextField id="title" required={true}  onChange={onChange} label="Title" />
            <TextAreaField id="description" required={true}  onChange={onChange} label="Description" rows="10" cols="20"/>
            <div className='d-flex align-items-center'>
              <GooglePlacesAutocomplete onPlaceSelected={handlePlaceSelected} onChange={onChangeLocation} label="Address 1 (Detect by google)" required={true} value={props.location ? props.location.address_1 : ''} />
              {loading && (
                <CircularProgress color="inherit" size={'small'} sx={{ width: '18px', height: '18px', marginLeft: '6px'}}/>
              )}
            </div>
            {/* <TextField id="address_1" required={true} onChange={onChangeLocation} label="Address 1" onLoad={() => onChangeLocation("address_1","")}  /> */}
            <TextField id="address_2" required={false} onChange={onChangeLocation} label="Address 2" onLoad={() => onChangeLocation("address_2","")} value={props.location ? props.location.address_2 : ''}  />
            <TextField id="city" required={true}  onChange={onChangeLocation} label="City" onLoad={() => onChangeLocation("city", "")}  value={props.location.city || ""} />
            <TextField id="state" required={true} onChange={onChangeLocation} label="State" onLoad={() => onChangeLocation("state","")}  value={props.location.state || ""}/>
            <TextField id="country" required={true} onChange={onChangeLocation} label="Country" onLoad={() => onChangeLocation("country", "")} value={props.location.country || ""} />
            <TextField id="zip" required={true} onChange={onChangeLocation} label="Zip" onLoad={() => onChangeLocation("zip", "")}  value={props.location.zip || ""}/>
            <div className='create-organization-wrapper col-md-10'>
            <h6 >Selected Region for in app Browsing:</h6>
            <SearchSelector
              id="country"
              required={false}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.countries}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="Country"
              prompt="Please search and select a country" />
            <SearchSelector
              id="state"
              required={false}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.states}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="State"
              prompt="Please select a country before select a state"
            />
            <SearchSelector
              id="city"
              required={false}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.cities}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="City"
              prompt="Please select a state before select a city"
            /> 
            </div>   
            <TextField id="longitude" type="number" required={true} onChange={onChangeLocation} label="Longtitude" onLoad={() => onChangeLocation("longitude", "")}  value={props.location.longitude || null} />
            <TextField id="latitude" type="number" required={true} onChange={onChangeLocation} label="Latitude" onLoad={() => onChangeLocation("latitude","")}   value={props.location.latitude || null}/>
            <TextField id="website" required={false}  onChange={onChange} label="Website" />
            <TextField id="buy_ticket_url" required={false}  onChange={onChange} label="Buy Ticket Url" />
            <ImageSelector id="card_image" required={true}  onChange={onChange} label="Card Image" />
            <ImageSelector id="profile_image" required={true}  onChange={onChange} label="Profile Image" />
            <DateTimeSelector id="start_time" required={true}  onChange={onChange} label="Start Time" />
            <DateTimeSelector id="end_time" required={true}  onChange={onChange} label="End Time" />
            <CheckboxSelector id="is_published" onChange={onChange} label="Publish" />
            <SubmitButton />
        </form>
    )
}

export default CreateEventForm;