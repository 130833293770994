import React, {useEffect, useState} from "react"
import OrganizationApi from "../services/organizations";
import LocationApi from "../services/locations";
import {useParams, useNavigate} from "react-router-dom";

import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"
import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageManager from "../fragments/form/ImageManager"
import SubmitButton from "../fragments/form/SubmitButton"
import DropdownSelector from "../fragments/form/DropdownSelector";
import SearchSelector from "../components/SearchSelector/RegionSelect";
import GooglePlacesAutocomplete from "../components/Organizations/GooglePlacesAutocomplete";
import CountryApi from "../services/countries";
import StateApi from "../services/states";
import MunicipalityApi from "../services/municipalities";
import CircularProgress from "@mui/material/CircularProgress";

function EditOrganizationForm(props) {
    const [formData, setFormData] = useState(new FormData());
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const organizationApi = new OrganizationApi(props.token);
    const locationApi = new LocationApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const navigate = useNavigate();
    const routeParams = useParams();
    const { organizationId } = routeParams

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const updateLocationFields = (id, name, value) => {
        let newLocation = {...props.location}
        if (id === 'country') {
            newLocation.country_id = value;
            newLocation.country = name;
          }
          if (id === 'state') {
            newLocation.state_id = value;
            newLocation.state = name;
          }
          if (id === 'city') {
            newLocation.city_id = value;
            newLocation.city = name;
          }
          props.handleSetLocation(newLocation);
      }

      const resetLocationFields = (id) => {
        if (!props.location) {
          return;
        }
    
        let newLocation = { ...props.location };
        if (id === 'country') {
          newLocation.country = '';
          newLocation.country_id = null;
          newLocation.state = '';
          newLocation.state_id = null;
          newLocation.city = '';
          newLocation.city_id = null;
        } else if (id === 'state') {
          newLocation.state = '';
          newLocation.state_id = null;
          newLocation.city = '';
          newLocation.city_id = null;
        } else if (id === 'city') {
          newLocation.city = '';
          newLocation.city_id = null;
        }
        props.handleSetLocation(newLocation);
      };

    const onChangeLocation = (id, value) => {
        let newLocation = {...props.location, [id]: value}
        props.handleSetLocation(newLocation);
    }

    const handlePlaceSelected = async(place) => {
      if (!place || !place.address_components || !place.geometry) {
        console.error("Invalid place object:", place);
        return;
      }
      const { address_components: components, geometry, formatted_address } = place;
      let country = "";
      let state = "";
      let city = "";
      let zip = "";
      let address_2 = "";
      const newLocation = { ...props.location };
      newLocation.address_1 = formatted_address;
      const lat = geometry?.location?.lat();
      const lng = geometry?.location?.lng();
      
      if (lat && lng) {
        newLocation.latitude = lat;
        newLocation.longitude = lng;
      }

      for (let component of components) {
        const { types, long_name, short_name } = component;
        if (types.includes("country")) {
          newLocation.country = long_name;
          country = long_name;
        }

        if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
          newLocation.state = component.long_name;
        }

        if (types.includes("administrative_area_level_2") && !state) {
          state = component.long_name;
          newLocation.state = component.long_name;
        }

        if (types.includes("locality")) {
          city = component.long_name;
          newLocation.city = component.long_name;
        }

        if (types.includes("postal_code")) {
          zip = component.long_name;
          newLocation.zip = component.long_name;
        }
        
        if (types.includes("subpremise")) {
          address_2 = long_name;
          newLocation.address_2 = long_name;
        }else{
          newLocation.address_2 = formatted_address
        }
      }
      setLoading(true);
      await getIds(newLocation)
      setLoading(false);
    };
    
    const getIds = async (locationData) => {
      try {
        let countryId = null;
        let stateId = null;
        let cityId = null;
  
        const newLocation = { ...locationData };
        let { country, state, city } = locationData
  
        if (country) {
          const countryResponse = await countryApi.getCountriesByName(country);
          const countryData = countryResponse.find(item =>
            item.country.trim().toLowerCase() === country.trim().toLowerCase()
          );
          if (countryData && countryData.id) {
            countryId = countryData?.id;
            newLocation.country_id = countryId
            newLocation.country = country
          } else {
            countryId = null;
            newLocation.country_id = null;
            props.handleSetLocation(newLocation);
            return;
          }
        }
  
        if (countryId && state) {
          const stateResponse = await stateApi.getStatesByName(countryId, state);
          const stateData = stateResponse.find(item =>
            item.state.trim().toLowerCase() === state.trim().toLowerCase()
          );
          if (stateData && stateData.id) {
            stateId = stateData?.id;
            newLocation.state_id = stateId
            newLocation.state = state
          } else {
            stateId = null;
            newLocation.state_id = null;
            props.handleSetLocation(newLocation);
            return;
          }
        }
  
        if (stateId && city) {
          const cityResponse = await municipalityApi.getCitiesByName(stateId, city);
          const cityData = cityResponse.find(item =>
            item.city.trim().toLowerCase() === city.trim().toLowerCase()
          );
          if (cityData && cityData.id) {
            cityId = cityData?.id;
            newLocation.city_id = cityId
            newLocation.city = city
          } else {
            cityId = null;
            newLocation.city_id = null
          }
        }
        props.handleSetLocation(newLocation);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        try {
          if (props.location.country_id === null || props.location.state_id === null || props.location.city_id === null) {
              delete props.location.country_id;
              delete props.location.state_id; 
              delete props.location.city_id;
          }
            if (props.organization) {
                props.location.name = props.organization.name;
                props.location.is_published = props.organization.is_published;
                if (!props.organization.location_id && props.location) {
                    props.location.organization_id = props.organization.id;
                    const newLocation = await locationApi.create(props.location);
                    formData.set("location_id", newLocation.id);
                } else if (props.organization.location_id && props.location) {
                    await locationApi.update(props.organization.location_id, props.location);
                }
                const organizationResponse = await organizationApi.update(organizationId, formData);
                if (routeParams.countryId && routeParams.stateId && routeParams.municipalityId) {
                    navigate(`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}`);
                } else {
                    navigate(`/organization/${organizationId}`);
                }
            }
        } catch (err) {
            console.error("Error during update:", err.message);
            setError(err.message);
            setMessage("");
        }
    };
    
    if(!props.organization) {
        return (<div>Loading...</div>)
    }
    return(
      <div>
        <Error message={error} />
        <Message message={message} />
            {
                props.organization.is_corkboard ?   <form onSubmit={handleOnSubmit}>
                <TextField id="name" required={true} value={props.organization.name} onChange={onChange} label="Title" />
                <CheckboxSelector id="is_published" value={props.organization.is_published} onChange={onChange} label="Publish" />
                <SubmitButton />
            </form>
            :
            <form onSubmit={handleOnSubmit}>
                <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category"  onLoad={() => onChange("category_id", 0)} selectedId={props.organization.category_id} />
                <TextField id="name" required={true} value={props.organization.name} onChange={onChange} label="Title" />
                <TextAreaField id="description" required={true} value={props.organization.description} onChange={onChange} label="Description" rows="10" cols="20"/>
                <TextField id="website_url" required={false} value={props.organization.website_url} onChange={onChange} label="Website" />
                <TextField id="twitter_name" required={false} value={props.organization.twitter_name}  onChange={onChange} label="Twitter Name" />
                <TextField id="facebook_name" required={false} value={props.organization.facebook_name}  onChange={onChange} label="Facebook Name" />
                <TextField id="instagram_name" required={false} value={props.organization.instagram_name}  onChange={onChange} label="Instagram Name" />
                <TextField id="linkedin_name" required={false} value={props.organization.linkedin_name}  onChange={onChange} label="LinkedIn Name" />
                <TextField id="email_address" required={false} value={props.organization.email_address}  onChange={onChange} label="Email Address" />
                <TextField id="phone_number" required={false} value={props.organization.phone_number}  onChange={onChange} label="Phone Number" />
                <div className='d-flex align-items-center'>
                  <GooglePlacesAutocomplete onPlaceSelected={handlePlaceSelected} onChange={onChangeLocation} label="Address 1 (Detect by google)" required={true} value={props.location ? props.location.address_1 : ''} />
                  {loading && (
                    <CircularProgress color="inherit" size={'small'} sx={{ width: '18px', height: '18px', marginLeft: '6px' }} />
                  )}
                </div>
                {/* <TextField id="address_1" required={true} value={props.location ? props.location.address_1 : ''} onChange={onChangeLocation} label="Address 1" /> */}
                <TextField id="address_2" required={false}  value={props.location ? props.location.address_2 : ''} onChange={onChangeLocation} label="Address 2" /> 
                <TextField id="city" required={true}   label="City" onLoad={() => onChangeLocation("city", "")}  value={props.location.city || ""} />
                <TextField id="state" required={true}  label="State" onLoad={() => onChangeLocation("state","")}  value={props.location.state || ""}/>
                <TextField id="country" required={true}  label="Country" onLoad={() => onChangeLocation("country", "")} value={props.location.country || ""} />
                <TextField id="zip" required={true} value={props.location ? props.location.zip : ''} onChange={onChangeLocation} label="Zip" />
                {/*  */}
                <div className='create-organization-wrapper col-md-10'>
                <h6 >Selected Region for in app Browsing:</h6>
                <SearchSelector 
                id="country"
                required={false} 
                isSubmitted={isSubmitted} 
                location={props.location} 
                collection={props.countries} 
                updateLocationFields={updateLocationFields} 
                handleSearchLocation={props.handleSearchLocation} 
                resetLocationFields={resetLocationFields} 
                label="Country" 
                prompt="Please search and select a country"/>
                <SearchSelector 
                id="state" 
                required={false}  
                isSubmitted={isSubmitted} 
                location={props.location} 
                collection={props.states} 
                updateLocationFields={updateLocationFields} 
                handleSearchLocation={props.handleSearchLocation} 
                resetLocationFields={resetLocationFields} 
                label="State" 
                prompt="Please select a country before select a state"/>
                <SearchSelector 
                id="city" 
                required={false}  
                isSubmitted={isSubmitted} 
                location={props.location} 
                collection={props.cities} 
                updateLocationFields={updateLocationFields} 
                handleSearchLocation={props.handleSearchLocation} 
                resetLocationFields={resetLocationFields}  
                label="City" 
                prompt="Please select a state before select a city"/> 
                </div>             
                <TextField id="longitude" type="number"  required={true} value={props.location ? props.location.longitude : null} onChange={onChangeLocation} label="Longtitude"/>
                <TextField id="latitude" type="number" required={true} value={props.location ? props.location.latitude : null} onChange={onChangeLocation} label="Latitude" />
                <ImageManager id="card_image" required={false} url={props.organization.card_image.url} label="Card Image" onChange={onChange} />
                <ImageManager id="profile_image" required={false} url={props.organization.profile_image.url} label="Profile Image" onChange={onChange} />
                <CheckboxSelector id="is_published" value={props.organization.is_published} onChange={onChange} label="Publish" />
                <SubmitButton />
            </form>
            }
        </div>
    )
}

export default EditOrganizationForm;