
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateOrganizationForm from "../../forms/CreateOrganizationForm"
import OrganizationApi from "../../services/organizations";
import CategoryApi from "../../services/categories";
import LocationApi from "../../services/locations";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateOrganizationPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [categories, setCategories] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [location, setLocation] = useState({
        address_1: "",
        address_2: "", 
        zip: null,
        country_id: null,
        state_id: null,
        city_id: null,
        longitude: null,
        latitude: null,
    });
    
    const categoryApi = new CategoryApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const locationApi = new LocationApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const routeParams = useParams();
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            let categories = await categoryApi.all();
            setCategories(Object.values(categories).filter(category => category.is_organization))
            // let countries = await countryApi.getlist(100);
            // let array = countries.map((e)=> ({label: e.country , id: e.id}))
            // setCountries([...array]) 
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        fetchData();
        const updatedLocation = { ...location };
        if (routeParams.countryId) {
            updatedLocation.country_id = routeParams.countryId;
        }
        if (routeParams.stateId) {
            updatedLocation.state_id = routeParams.stateId;
        }
        if (routeParams.municipalityId) {
            updatedLocation.city_id = routeParams.municipalityId;
        }
        if (routeParams.countryId || routeParams.stateId || routeParams.municipalityId) {
            setLocation(updatedLocation);
        }
    }, []);

    // const fetchStatesByCountryId = async(countryId)=>{
    //     try {
    //         if(countryId){
    //             let states = await stateApi.getlist(countryId, 100)
    //             setStates([...states])
    //         }else{
    //             setStates([])
    //         }
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // }

    // const fetchCitiesByStateId = async(stateId)=>{
    //     try {
    //         if(stateId){
    //             let cities = await municipalityApi.getlist(stateId, 100)
    //             setCities([...cities])
    //         }else{
    //             setCities([])
    //         }
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // }

    // useEffect(() => {
    //     if (location?.country_id) {
    //         fetchStatesByCountryId(location.country_id);
    //     } else {
    //         setStates([])
    //     }
    // }, [location?.country_id]);

    // useEffect(() => {
    //     if (location?.state_id) {
    //         fetchCitiesByStateId(location.state_id);
    //     } else {
    //         setCities([])
    //     }
    // }, [location?.state_id]);


    const handleSearchCountry =async(value)=>{
        try {
            if(value){
                let response = await countryApi.getCountryListByLetter(value)
                let arrCountry =  response.countries.map((e)=>({label: e.country , value: e.id,...e}))
                setCountries([...arrCountry])
            }else{
                setCountries([])
            }
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        }
      
    }

    const handleSearchState =async(countryId, value)=>{
        try {
            if(value){
                let response = await stateApi.getStateListByLetter(countryId,value)
                let arrState=  response.states.map((e)=>({label: e.state , value: e.id,...e}))
                setStates([...arrState])
            }else{
                setStates([])
            }
        } catch (error) {
            console.error("Failed to fetch states:", error);
        }
      
    }

    const handleSearchCity = async (stateId, value)=>{
        try {
            if(value){
                let response = await municipalityApi.getCityListByLetter(stateId,value)
                let arrCity=  response.cities.map((e)=>({label: e.city , value: e.id,...e}))
                setCities([...arrCity])
            }else{
                setCities([])
            }
        } catch (error) {
            console.error("Failed to fetch cities:", error);
        }
    }

    const handleSetLocation=(newLocation)=>{
        setLocation(newLocation)
    }

    const handleSearchLocation = (id, countryId, stateId, value)=>{
        if(id === 'country'){
            handleSearchCountry(value)
        }
        else if(id === 'state'){
            handleSearchState(countryId, value)
        }
        else if(id === 'city'){
            handleSearchCity(stateId, value)
        }else{
            //
        }
    }

    const onSubmit = async(formData, locationData) => {
        try {
            if (locationData.country_id === null || locationData.state_id === null || locationData.city_id === null) {
                delete locationData.country_id;
                delete locationData.state_id; 
                delete locationData.city_id;
            }
            let newOrganization = await organizationApi.create(formData);
            locationData.organization_id = newOrganization.id;
            locationData.name = newOrganization.name;
            locationData.is_published = newOrganization.is_published;
            let newLocation = await locationApi.create(locationData);
            formData.set("location_id", newLocation.id);

            await organizationApi.update(newOrganization.id, formData);
            
            if (routeParams.countryId && routeParams.stateId && routeParams.municipalityId) {
                navigate(`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${newOrganization.id}`);
            } else {
                navigate(`/organization/${newOrganization.id}`);
            }
        } catch (err) {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        }
    }

    const handleBack=()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId){
            return  `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}`   
        }else{
            return '/organizations'
        }
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Create Organization`}
        >
            <Error message={error} />
            <Message message={message} />
            <CreateOrganizationForm
                {...props}
                onSubmit={onSubmit}
                categories={categories}
                countries={countries}
                states={states}
                cities={cities}
                location={location}
                handleSetLocation={handleSetLocation}
                handleSearchLocation={handleSearchLocation}
                />
        </Page>
    );
}

export default CreateOrganizationPage;