import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import EventsApi from "../../services/events";
import OrganizationApi from "../../services/organizations";
import CategoryApi from "../../services/categories";
import LocationApi from "../../services/locations";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities";
import Page from "../Page"
import Loading from "../../fragments/Loading"
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import CreateEventForm from "../../forms/CreateEventForm"
import OrganizationLocationHeaderNav from "../../fragments/nav/OrganizationLocationHeaderNav"

const CreateOrganizationEventPage = (props) => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [organization, setOrganization] = useState();
    const [locations, setLocations] = useState();
    const [categories, setCategories] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [location, setLocation] = useState({
        address_1: "",
        address_2: "", 
        zip: null,
        country_id: null,
        state_id: null,
        city_id: null,
        longitude: null,
        latitude: null,
    });
    const eventApi = new EventsApi(props.token);
    const categoryApi = new CategoryApi(props.token);
    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);
    const routeParams = useParams();
    const { organizationId } = routeParams
    const navigate = useNavigate();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });

        categoryApi.all().then((categories) => {
            setCategories(Object.values(categories.filter(category => category.is_event)));
        })
        .catch((err) => {
            console.error(err.message);
        });
        
        locationApi.getByOrganizationId(organizationId).then((response) => {
            setLocations(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        const updatedLocation = { ...location };
        if (routeParams.countryId) {
            updatedLocation.country_id = routeParams.countryId;
        }
        if (routeParams.stateId) {
            updatedLocation.state_id = routeParams.stateId;
        }
        if (routeParams.municipalityId) {
            updatedLocation.city_id = routeParams.municipalityId;
        }
        if (routeParams.countryId || routeParams.stateId || routeParams.municipalityId) {
            setLocation(updatedLocation);
        }
    }, []);

    const onSubmit = async(formData, locationData) => {
        try {
            if (locationData.country_id === null || locationData.state_id === null || locationData.city_id === null) {
                delete locationData.country_id;
                delete locationData.state_id; 
                delete locationData.city_id;
            }
            locationData.organization_id = organization.id;
            locationData.name = organization.name;
            locationData.is_published = organization.is_published;
            let newLocation = await locationApi.create(locationData);
            formData.set("organization_id", organizationId);
            formData.set("location_id", newLocation.id);
            
            let response = await eventApi.create(formData)
            if (routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId) {
                navigate(`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/events`)
            } else {
                navigate(`/organization/${organizationId}/events`);
            }
        } catch (err) {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        }
     
    }
    
    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/events`
        }else{
            return `/organization/${organizationId}/events`
        }
    }

    const handleSearchCountry =async(value)=>{
        try {
            if(value){
                let response = await countryApi.getCountryListByLetter(value)
                let arrCountry =  response.countries.map((e)=>({label: e.country , value: e.id,...e}))
                setCountries([...arrCountry])
            }else{
                setCountries([])
            }
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        }
      
    }

    const handleSearchState =async(countryId, value)=>{
        try {
            if(value){
                let response = await stateApi.getStateListByLetter(countryId,value)
                let arrState=  response.states.map((e)=>({label: e.state , value: e.id,...e}))
                setStates([...arrState])
            }else{
                setStates([])
            }
        } catch (error) {
            console.error("Failed to fetch states:", error);
        }
      
    }

    const handleSearchCity = async (stateId, value)=>{
        try {
            if(value){
                let response = await municipalityApi.getCityListByLetter(stateId,value)
                let arrCity=  response.cities.map((e)=>({label: e.city , value: e.id,...e}))
                setCities([...arrCity])
            }else{
                setCities([])
            }
        } catch (error) {
            console.error("Failed to fetch cities:", error);
        }
    }

    const handleSearchLocation = (id, countryId, stateId, value)=>{
        if(id === 'country'){
            handleSearchCountry(value)
        }
        else if(id === 'state'){
            handleSearchState(countryId, value)
        }
        else if(id === 'city'){
            handleSearchCity(stateId, value)
        }else{
            //
        }
    }
    const handleSetLocation=(newLocation)=>{
        setLocation(newLocation)
    }

    if(!organization || !categories || !locations) {
        return (<Loading />)
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Create Event`}
        >
            {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ?
                <OrganizationLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId} isShowArchive={true}/>
                :
                <OrganizationHeaderNav organizationId={organizationId} isShowArchive={true} />
            }
            <Error message={error} />
            <Message message={message} />
            <CreateEventForm
                onSubmit={onSubmit}
                organization={organization}
                locations={locations}
                categories={categories}
                countries={countries}
                states={states}
                cities={cities}
                location={location}
                handleSetLocation={handleSetLocation}
                handleSearchLocation={handleSearchLocation}
                {...props}  />
        </Page>
    );
}

export default CreateOrganizationEventPage;