import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";

import EventsApi from "../../services/events";
import CategoryApi from "../../services/categories";
import LocationApi from "../../services/locations";
import OrganizationApi from "../../services/organizations";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities"

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"
import Loading from "../../fragments/Loading";
import EditEventForm from "../../forms/EditEventForm";
import Page from "../Page";

let defaultLocation ={
    address_1: "",
    address_2: "", 
    zip: null,
    country_id: null,
    state_id: null,
    city_id: null,
    longitude: null,
    latitude: null,
}

const EditOrganizationEventPage = (props) => {
    const [event, setEvent] = useState();
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [locations, setLocations] = useState();
    const [categories, setCategories] = useState();
    const [organization, setOrganization] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [location, setLocation] = useState(defaultLocation);
    const [locationId, setLocationId] = useState('')

    const eventsApi = new EventsApi(props.token);
    const categoryApi = new CategoryApi(props.token);
    const locationApi = new LocationApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const routeParams = useParams();
    const { organizationId, eventId } = routeParams;
    const navigate = useNavigate();

    useEffect(() => {
        categoryApi.all().then((categories) => {
            setCategories(Object.values(categories.filter(category => category.is_event)));
        })
        eventsApi.getById(eventId).then((response) => {
            setEvent(response)
            setLocationId(response.location_id)
        })
        locationApi.getByOrganizationId(organizationId).then((locations) => {
            setLocations(Object.values(locations));
        })
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        let updatedLocation = { ...location };
        if (routeParams.countryId) {
            updatedLocation.country_id = routeParams.countryId;
        }
        if (routeParams.stateId) {
            updatedLocation.state_id = routeParams.stateId;
        }
        if (routeParams.municipalityId) {
            updatedLocation.city_id = routeParams.municipalityId;
        }
        if (routeParams.countryId || routeParams.stateId || routeParams.municipalityId) {
            setLocation(updatedLocation);
        }
    }, []);

    const getLocationData = async (locationId) => {
        try {
            if (!locationId) {
                setLocation(defaultLocation);
                return;
            }
         
            let locationData = await locationApi.getById(locationId);
           
            if (routeParams.countryId || locationData.country_id) {
                locationData.country_id = routeParams.countryId || locationData.country_id;
            } else if (locationData?.country) {
                const countryResponse = await countryApi.getCountriesByName(locationData.country);
                const country = countryResponse.find(item =>
                    item.country.trim().toLowerCase() === locationData.country.trim().toLowerCase()
                );
                locationData.country_id = country?.id || null;
            } else {
                locationData.country_id = null;
            }

            if (routeParams.stateId || locationData.state_id) {
                locationData.state_id = routeParams.stateId || locationData.state_id;
            } else if (locationData?.state && locationData.country_id) {
                const stateResponse = await stateApi.getStatesByName(locationData.country_id, locationData.state);
                const state = stateResponse.find(item =>
                    item.state.trim().toLowerCase() === locationData.state.trim().toLowerCase()
                );
                locationData.state_id = state?.id || null;
            } else {
                locationData.state_id = null;
            }

            if (routeParams.cityId || locationData.city_id) {
                locationData.city_id = routeParams.cityId || locationData.city_id;
            } else if (locationData?.city && locationData.state_id) {
                const cityResponse = await municipalityApi.getCitiesByName(locationData.state_id, locationData.city);
                const city = cityResponse.find(item =>
                    item.city.trim().toLowerCase() === locationData.city.trim().toLowerCase()
                );
                locationData.city_id = city?.id || null;
            } else {
                locationData.city_id = null;
            }

            setLocation({ ...locationData });
        } catch (err) {
            console.error("Error fetching location data:", err.message);
        }
    };

    useEffect(()=>{
        if(locationId){
            getLocationData(locationId)
        }
    },[locationId])

    const handleSetLocation=(newLocation)=>{
        setLocation(newLocation)
    }

    const handleSearchCountry =async(value)=>{
        try {
            if(value){
                let response = await countryApi.getCountryListByLetter(value)
                let arrCountry =  response.countries.map((e)=>({label: e.country , value: e.id,...e}))
                setCountries([...arrCountry])
            }else{
                setCountries([])
            }
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        }
      
    }

    const handleSearchState =async(countryId, value)=>{
        try {
            if(value){
                let response = await stateApi.getStateListByLetter(countryId,value)
                let arrState=  response.states.map((e)=>({label: e.state , value: e.id,...e}))
                setStates([...arrState])
            }else{
                setStates([])
            }
        } catch (error) {
            console.error("Failed to fetch states:", error);
        }
      
    }

    const handleSearchCity = async (stateId, value)=>{
        try {
            if(value){
                let response = await municipalityApi.getCityListByLetter(stateId,value)
                let arrCity=  response.cities.map((e)=>({label: e.city , value: e.id,...e}))
                setCities([...arrCity])
            }else{
                setCities([])
            }
        } catch (error) {
            console.error("Failed to fetch cities:", error);
        }
    }

    const handleSearchLocation = (id, countryId, stateId, value)=>{
        if(id === 'country'){
            handleSearchCountry(value)
        }
        else if(id === 'state'){
            handleSearchState(countryId, value)
        }
        else if(id === 'city'){
            handleSearchCity(stateId, value)
        }else{
            //
        }
    }

    const onSubmit = async(formData, locationData) => {
        try {
            if (locationData.country_id === null || locationData.state_id === null || locationData.city_id === null) {
                delete locationData.country_id;
                delete locationData.state_id; 
                delete locationData.city_id;
            }
            let locationIdValue = locationId || locationData.id
            await locationApi.update(locationIdValue,locationData);
            let response = await eventsApi.update(eventId, formData)
            if (routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId) {
                navigate(`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/${eventId}`);
            } else {
                navigate(`/organization/${organizationId}/event/${eventId}`);
            }
        } catch (err) {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        }
    }

    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/events`
        }else{
            return `/organization/${organizationId}/events`
        }
    }

    if(!event || !organization || !locations || !categories) {
        return (<Loading />)
    }
    return (
        <Page
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Edit Event: ${event.title}`}
        >
            <Error message={error} />
            <Message message={message} />
            <EditEventForm 
                {...props}
                event={event}
                locations={locations}
                categories={categories}
                organization={organization}
                countries={countries}
                states={states}
                cities={cities}
                location={location}
                handleSetLocation={handleSetLocation}
                handleSearchLocation={handleSearchLocation}
                onSubmit={onSubmit} />
        </Page>

    );
}

export default EditOrganizationEventPage;
