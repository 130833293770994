import React, { useEffect, useRef , useState} from "react";
import FormGroup from "../../fragments/form/FormGroup"

const GooglePlacesAutocomplete = ({ onPlaceSelected, ...props }) => {
    const [data, setData] = useState(props.value);
    const autocompleteRef = useRef(null);

    const onChange = (e) => {
        setData(e.target.value);
        props.onChange(props.id, e.target.value);
    }

    useEffect(() => {
        setData(props.value);
    }, [props.value]);


    useEffect(() => {
        const initAutocomplete = () => {
            if (!window.google || !window.google.maps) {
                console.error("Google Maps JavaScript API is not loaded.");
                return;
            }

            const places = window.google.maps.places;

            if (!places) {
                console.error("Places library is not loaded.");
                return;
            }

            const autocomplete = new places.Autocomplete(autocompleteRef.current, {
                componentRestrictions: { country: "ca" },
            });

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place && onPlaceSelected) {
                    onPlaceSelected(place);
                }
            });
        };

        initAutocomplete();
    }, [onPlaceSelected]);

    return (
        <FormGroup {...props}>
            <input ref={autocompleteRef} type="text" required={props.required}  name={props.id} value={data} placeholder=""  onChange={onChange} className="form-control" />
        </FormGroup>
    )
};

export default GooglePlacesAutocomplete;
