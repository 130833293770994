import React, {useState} from 'react';

// import DropdownSelector from "../fragments/form/DropdownSelector"
import TextField from "../fragments/form/TextField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import SubmitButton from "../fragments/form/SubmitButton"
import LocationSelect from "../components/SearchSelector/LocationSelect"
import CircularProgress from "@mui/material/CircularProgress";
import GooglePlacesAutocomplete from "../components/Organizations/GooglePlacesAutocomplete";
import CountryApi from "../services/countries";
import StateApi from "../services/states";
import MunicipalityApi from "../services/municipalities";

const CreateLocationForm = (props) => {
    const [formData, setFormData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const updateLocationFields = (id, name, value) => {
        let newLocation = {...formData}
        if (id === 'country') {
          newLocation.country_id = value;
          newLocation.country = name;
          props.handleSetCountryId(value)
        }
        if (id === 'state') {
          newLocation.state_id = value;
          newLocation.state = name;
          props.handleSetStateId(value)
        }
        if (id === 'city') {
          newLocation.city_id = value;
          newLocation.city = name;
        }
        setFormData(newLocation);
      }
    
    const resetLocationFields =(id)=>{
        if(id === 'country'){
            formData.country = '';
            formData.country_id =null;
            formData.state = '';
            formData.state_id = null;
            formData.city = '';
            formData.city_id = null
        }
        if(id === 'state'){
            formData.state = '';
            formData.state_id = null;
            formData.city = '';
            formData.city_id = null
        }
        if(id === 'city'){
            formData.city = '';
            formData.city_id = null
        }
        setFormData({...formData});
    }

    const onChange = (id, value) => {
        formData[id] = value;
        setFormData(formData);
    }

    const handlePlaceSelected = async(place) => {
        if (!place || !place.address_components || !place.geometry) {
          console.error("Invalid place object:", place);
          return;
      }
        const { address_components: components, geometry , formatted_address} = place;
        let country = "";
        let state = "";
        let city = "";
        let zip = ""; 
        let address_2 = "";

        const newLocation = { ...formData };
        newLocation.address_1 = formatted_address;
    
        const lat = geometry?.location?.lat();
        const lng = geometry?.location?.lng();
      
        if (lat && lng) {
          newLocation.latitude = lat;
          newLocation.longitude = lng;
        }
    
        for(let component of components){
           const { types, long_name, short_name } = component;
           if (types.includes("country")) {
                newLocation.country = long_name;
                country = long_name;
           }
    
          if (types.includes("administrative_area_level_1")) {
            state = component.long_name;
            newLocation.state = component.long_name;
          }
    
          if (types.includes("administrative_area_level_2") && !state) {
            state = component.long_name;
            newLocation.state = component.long_name;
          }
    
          if (types.includes("locality")) {
            city = component.long_name;
            newLocation.city = component.long_name;
          }
    
          if (types.includes("postal_code")) {
            zip = component.long_name;
            newLocation.zip = component.long_name;
          }

          if (types.includes("subpremise")) {
            address_2 = long_name;
            newLocation.address_2 = long_name;
          }else{
            newLocation.address_2 = formatted_address
          }
          
        }
        setLoading(true);
        await getIds(newLocation)
        setLoading(false);
      };
    
      const getIds = async (locationData) => {
        try {
          let countryId = null;
          let stateId = null;
          let cityId = null;
    
          const newLocation = { ...locationData };
          let { country, state, city } = locationData
    
          if (country) {
            const countryResponse = await countryApi.getCountriesByName(country);
            const countryData = countryResponse.find(item =>
              item.country.trim().toLowerCase() === country.trim().toLowerCase()
            );
            if (countryData && countryData.id) {
              countryId = countryData?.id;
              newLocation.country_id = countryId
              newLocation.country = country
            } else {
              countryId = null;
              newLocation.country_id = null;
              setFormData(newLocation);
              return;
            }
          }
    
          if (countryId && state) {
            const stateResponse = await stateApi.getStatesByName(countryId, state);
            const stateData = stateResponse.find(item =>
              item.state.trim().toLowerCase() === state.trim().toLowerCase()
            );
            if (stateData && stateData.id) {
              stateId = stateData?.id;
              newLocation.state_id = stateId
              newLocation.state = state
            } else {
              stateId = null;
              newLocation.state_id = null;
              setFormData(newLocation);
              return;
            }
          }
    
          if (stateId && city) {
            const cityResponse = await municipalityApi.getCitiesByName(stateId, city);
            const cityData = cityResponse.find(item =>
              item.city.trim().toLowerCase() === city.trim().toLowerCase()
            );
            if (cityData && cityData.id) {
              cityId = cityData?.id;
              newLocation.city_id = cityId
              newLocation.city = city
            } else {
              cityId = null;
              newLocation.city_id = null
            }
          }
          setFormData(newLocation);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    
    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        props.onSubmit(formData);
    }

    return(
        <div>
            <form onSubmit={onSubmit}>
                <TextField
                    id="name"
                    onChange={onChange}
                    label="Name"
                    required={true} 
                    onLoad={() => onChange("name", "")} 
                />
                <div className='d-flex align-items-center'>
                    <GooglePlacesAutocomplete onPlaceSelected={handlePlaceSelected} onChange={onChange} label="Address 1 (Detect by google)" required={true} value={formData.address_1 ||''} />
                    {loading && (
                        <CircularProgress color="inherit" size={'small'} sx={{ width: '18px', height: '18px', marginLeft: '6px' }} />
                    )}
                </div>
                {/* <TextField
                    id="address_1"
                    onChange={onChange}
                    label="Address1"
                    required={true} 
                    onLoad={() => onChange("address_1", "")} 
                /> */}
                <TextField
                    id="address_2"
                    onChange={onChange}
                    label="Address2"
                    required={false}
                    onLoad={() => onChange("address_2", "")} 
                />
                <TextField id="city" required={true}  label="City" onChange={onChange} onLoad={() => onChange("city", "")} value={formData.city || ""} />
                <TextField id="state" required={true}  label="State" onChange={onChange} onLoad={() => onChange("state", "")} value={formData.state || ""} />
                <TextField id="country" required={true}  label="Country" onChange={onChange} onLoad={() => onChange("country", "")} value={formData.country || ""} />
                <TextField id="zip" required={true} value={formData.zip || ''} onChange={onChange} label="Zip" />
                <div className='create-organization-wrapper col-md-10'>
                <h6 >Selected Region for in app Browsing:</h6>
                <LocationSelect
                    id="country"
                    required={false}
                    isSubmitted={isSubmitted}
                    formData={formData}
                    collection={props.countries}
                    countryId={props.countryId}
                    stateId={props.stateId}
                    handleSearchLocation={props.handleSearchLocation}
                    updateLocationFields={updateLocationFields}
                    resetLocationFields={resetLocationFields}
                    label="Country"
                    prompt="Please search and select a country"
                />
                <LocationSelect
                    id="state"
                    required={false}
                    isSubmitted={isSubmitted}
                    formData={formData}
                    collection={props.states}
                    countryId={props.countryId}
                    stateId={props.stateId}
                    handleSearchLocation={props.handleSearchLocation}
                    updateLocationFields={updateLocationFields}
                    resetLocationFields={resetLocationFields}
                    label="State"
                    prompt="Please select a country before select a state"
                />
                <LocationSelect
                    id="city"
                    required={false}
                    isSubmitted={isSubmitted}
                    formData={formData}
                    collection={props.cities}
                    countryId={props.countryId}
                    stateId={props.stateId}
                    handleSearchLocation={props.handleSearchLocation}
                    updateLocationFields={updateLocationFields}
                    resetLocationFields={resetLocationFields}
                    label="City"
                    prompt="Please select a state before select a city"
                />
                 </div>  
                <TextField
                    id="longitude"
                    onChange={onChange}
                    label="Longitude"
                    value={formData.longitude || null}
                    required={true} 
                    onLoad={() => onChange("longitude", "")} 
                />
                <TextField
                    id="latitude"
                    onChange={onChange}
                    label="Latitude"
                    required={true} 
                    value={formData.longitude || null}
                    onLoad={() => onChange("latitude", "")} 
                />
                <CheckboxSelector
                    id="is_published"
                    onChange={onChange}
                    label="Publish"
                    onLoad={() => onChange("is_published", "0")}
                />
                <SubmitButton />
            </form>
        </div>
    )
}

export default CreateLocationForm;